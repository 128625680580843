import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
}

const LastNameInput: React.FC<Props> = ({ control }) => {
  return (
    <TextComponent
      name="lastName"
      control={control}
      label="Last Name"
      rules={{
        required: { value: true, message: 'Enter last name' },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default LastNameInput;
