import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

const personNumberBirthdayMatch = (personNumber: string, birthDate: Date) => {
  if (personNumber === null || personNumber === undefined || personNumber === '') return true;

  const birthdayDateFormatted = new Date(
    birthDate.getTime() + Math.abs(birthDate.getTimezoneOffset() * 60000)
  ).toISOString();

  if (personNumber.length > 0) {
    const YYMMDDFromDate =
      birthdayDateFormatted !== ''
        ? birthdayDateFormatted.slice(2, 4) + birthdayDateFormatted.slice(5, 7) + birthdayDateFormatted.slice(8, 10)
        : '-1-1-1'; // always invalid if birthday date is empty
    const YYMMDDFromPersonNumber = personNumber.slice(0, 6);

    // check if length is 11, the last 4 character are digits and if it matches birthday date
    if (YYMMDDFromDate !== YYMMDDFromPersonNumber) {
      return false;
    }
  }

  return true;
};

const personNumberCorrectFormat = (personNumber: string) => {
  if (personNumber === null || personNumber === undefined || personNumber === '') return true;

  if (personNumber.length > 0) {
    // check if length is 11, the last 4 character are digits and if it matches birthday date
    if (
      personNumber.length !== 11 ||
      !/^[0-9]+$/.test(personNumber.slice(8, 11)) ||
      !/^[T0-9]$/.test(personNumber.slice(7, 8))
    ) {
      return false;
    }
  }
  return true;
};

interface Props {
  control: Control<any, any>;
  getValues: (x: string) => string;
}

const PersonalNumberInput: React.FC<Props> = ({ control, getValues }) => {
  return (
    <TextComponent
      name="personNumber"
      control={control}
      label="Personal Number (optional)"
      helperText="YYMMDD-XXXX or YYMMDD-TXXX"
      rules={{
        validate: {
          birthdayMatch: (v: any) =>
            personNumberBirthdayMatch(v, getValues('birthdayDate') as any)
              ? true
              : 'Birthday and Personal Number do not match',
          validLength: (v: any) => (personNumberCorrectFormat(v) ? true : 'Wrong format YYMMDD-XXXX'),
        },
        maxLength: { value: 11, message: 'Too long input' },
      }}
      style={{
        marginTop: '16px',
      }}
    />
  );
};

export default PersonalNumberInput;
