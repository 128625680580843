import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

export interface MutationProps {
  email: string;
}

const useRequestConfirmEmail = () => {
  const projectId = useProjectId();
  const openFeedback = useOpenFeedback();
  return useMutation({
    mutationFn: async ({ email }: MutationProps) => {
      await Backend.request('post', `/account/email/${projectId}`, {}, { email });
    },
    onSuccess: () => {
      openFeedback.openSuccess('E-mail sent!');
    },
  });
};

export default useRequestConfirmEmail;
