import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';
import useRequestConfirmEmail from '../../../../queries/user/useRequestConfirmEmail';

interface Props {
  email: string;
}

/**
 * Renders success creation page
 *
 * This page is divided also
 */
const StepCreated: React.FC<Props> = ({ email }) => {
  const requestConfirmEmail = useRequestConfirmEmail();

  const resendEmail = async () => {
    requestConfirmEmail.mutate({ email });
  };

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Verify your e-mail address
      </Typography>
      <p style={{ marginTop: '5px', marginBottom: '16px' }}>
        We have sent a verification link to your e-mail address <a href={`mailto:${email}`}>{email}</a>. Please follow
        the instruction in the e-mail.
      </p>
      <LoadingButton
        style={{ width: '100%', marginBottom: '40px' }}
        onClick={resendEmail}
        variant="contained"
        color="secondary"
        loading={requestConfirmEmail.isPending}
      >
        Resend E-mail
      </LoadingButton>
    </>
  );
};

export default StepCreated;
