import CommunicationCheckbox from 'containers/forms/CommunicationCheckbox';
import React from 'react';
import { Control } from 'react-hook-form';
import { GetGeneralOptionsResponse } from 'types/types';
import DateOfBirthInput from '/containers/forms/DateOfBirthInput';
import FirstNameInput from '/containers/forms/FirstNameInput';
import GenderSelect from '/containers/forms/GenderSelect';
import LastNameInput from '/containers/forms/LastNameInput';
import NationalitySelect from '/containers/forms/NationalitySelect';
import PersonalNumberInput from '/containers/forms/PersonalNumberInput';
import PhoneNumberInput from '/containers/forms/PhoneNumberInput';
import TermsOfUseCheckbox from '/containers/forms/TermsOfUseCheckbox';

interface Props {
  control: Control<any, any>;
  getValues: (x: string) => any;
  profileOptions: GetGeneralOptionsResponse;
  projectId: string | number;
}

/**
 * Renders General Information of Register page
 */
const RegisterStepOne: React.FC<Props> = ({ control, getValues, profileOptions, projectId }) => {
  return (
    <>
      <FirstNameInput control={control} />
      <LastNameInput control={control} />
      <DateOfBirthInput control={control} />
      <GenderSelect control={control} profileOptions={profileOptions} />
      <NationalitySelect control={control} profileOptions={profileOptions} />
      <PersonalNumberInput control={control} getValues={getValues} />
      <PhoneNumberInput control={control} />
      <TermsOfUseCheckbox control={control} projectId={projectId} />
      <CommunicationCheckbox control={control} />
    </>
  );
};

export default RegisterStepOne;
