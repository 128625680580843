import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
}

const PhoneNumberInput: React.FC<Props> = ({ control }) => {
  return (
    <TextComponent
      prefix="+"
      name="phoneNumber"
      control={control}
      label="Phone Number (optional)"
      rules={{
        // eslint-disable-next-line no-useless-escape
        pattern: { value: /^[0-9-\[\]() ]+$/, message: 'Wrong format, valid characters: 0-9, -, [], (), space' },
        maxLength: { value: 20, message: 'Too long input' },
      }}
    />
  );
};

export default PhoneNumberInput;
