import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
  getValues: (name: string) => string;
  label?: string;
  name?: string;
}

const ConfirmEmailInput: React.FC<Props> = ({ control, getValues, label, name }) => {
  return (
    <TextComponent
      name={name || 'confirmEmail'}
      control={control}
      label={label || 'Confirm E-mail'}
      rules={{
        required: { value: true, message: 'Confirm e-mail' },
        pattern: {
          value:
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          message: 'Invalid E-mail address',
        },
        validate: {
          emailsMatch: (v: any) =>
            v.toLowerCase() === getValues('email').toLowerCase() ? true : 'E-mails does not match',
        },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default ConfirmEmailInput;
