import { StudyOption } from 'types/types';

/**
 * Generates list of types from studyInfo
 * @param studyInfo study info from backend
 * @returns list of types available sorted alphabetically
 */
export const generateTypesFromStudyInfo = (studyInfo: StudyOption[]): string[] => {
  if (!studyInfo) return [];
  const set = new Set<string>();
  studyInfo.forEach((studyProgram: StudyOption) => set.add(studyProgram.typeEn));
  const returnArray = Array.from(set).sort();
  return returnArray;
};

export const random = () => {
  return null;
};
