import React from 'react';
import { Control } from 'react-hook-form';
import SelectComponent from '/components/form/Select';

interface Props {
  control: Control<any, any>;
  profileOptions: any;
}

const GenderSelect: React.FC<Props> = ({ control, profileOptions }) => {
  return (
    <SelectComponent
      control={control}
      name="sex"
      label="Gender"
      rules={{
        required: { value: true, message: 'Choose a gender' },
      }}
      options={profileOptions?.gender ? profileOptions.gender : []}
    />
  );
};

export default GenderSelect;
