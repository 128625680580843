import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface ConfirmPasswordInputProps {
  control: Control<any, any>;
  getValues: (formValue: string) => any;
  name?: string;
  label?: string;
}

const ConfirmPasswordInput: React.FC<ConfirmPasswordInputProps> = ({ control, getValues, name, label }) => {
  return (
    <TextComponent
      name={name || 'confirmPassword'}
      control={control}
      label={label || 'Confirm Password'}
      rules={{
        required: { value: true, message: 'Enter confirm password' },
        validate: {
          passwordMatch: (v: string) => (v === getValues('password') ? true : 'Passwords does not match'),
        },
      }}
      type="password"
    />
  );
};

export default ConfirmPasswordInput;
