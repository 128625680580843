import React from 'react';

import Typography from '@mui/material/Typography';
import { Control } from 'react-hook-form';
import CheckComponent from '/components/form/Check';

interface Props {
  control: Control<any, any>;
  projectId: string | number;
}

const TermsOfUseCheckbox: React.FC<Props> = ({ control, projectId }) => {
  return (
    <CheckComponent
      name="termsAgree"
      control={control}
      rules={{
        validate: {
          isTrue: (v: any) => (v === true ? true : 'You must accept'),
        },
      }}
      noMargin
      label={
        <>
          <Typography variant="body2">
            I agree with the&nbsp;
            <a target="_blank" rel="noopener noreferrer" href={`/${projectId}/about/terms`}>
              terms of use
            </a>
          </Typography>
        </>
      }
    />
  );
};

export default TermsOfUseCheckbox;
