import React from 'react';
import EmailInput from '/containers/forms/EmailInput';
import ConfirmEmailInput from '/containers/forms/ConfirmEmailInput';
import PasswordInput from '/containers/forms/PasswordInput';
import ConfirmPasswordInput from '/containers/forms/ConfirmPasswordInput';
import { Control } from 'react-hook-form';

interface Props {
  control: Control<any, any>;
  getValues: (x: string) => any;
}

/**
 * Render Account Information for register page
 */
const RegisterStepTwo: React.FC<Props> = ({ control, getValues }) => {
  return (
    <>
      <EmailInput control={control} />
      <ConfirmEmailInput control={control} getValues={getValues} />
      <PasswordInput control={control} />
      <ConfirmPasswordInput control={control} getValues={getValues} />
    </>
  );
};

export default RegisterStepTwo;
