import React from 'react';
import { Control } from 'react-hook-form';
import { StudyOption } from 'types/types';
import { generateTypesFromStudyInfo } from './utils';
import SelectComponent from '/components/form/Select';

/**
 * This exists to check when user presses submit that the study info and type match
 */
const validateProgramAndType = (selectedProgramId: any, studyInfo: StudyOption[], studyType: any) => {
  const chosenProgram = studyInfo.find(
    (studyProgram: StudyOption) => studyProgram.studyProgramId === selectedProgramId
  );
  if (chosenProgram?.typeEn === studyType) return true;
  return false;
};

interface Props {
  control: Control<any, any>;
  studyInfo: StudyOption[];
  watchStudyType: any;
}

const StudyProgramSelect: React.FC<Props> = ({ control, studyInfo, watchStudyType }) => {
  const studyTypes = generateTypesFromStudyInfo(studyInfo);

  return (
    <SelectComponent
      control={control}
      name="studyProgram"
      label="Study Programme"
      rules={{
        required: { value: true, message: 'Enter Study Program' },
        validate: {
          programMatchType: (v: any) =>
            validateProgramAndType(v, studyInfo, studyTypes[watchStudyType - 1]) ? true : 'Enter Study Program',
        },
      }}
      options={
        studyInfo
          ? studyInfo
              .filter((studyProgram: any) => studyProgram.typeEn === studyTypes[watchStudyType - 1])
              .map((studyProgram: any) => ({
                value: studyProgram.studyProgramId,
                label: `${studyProgram.nameEn} ${
                  studyProgram.abbreviation !== '' ? `(${studyProgram.abbreviation})` : ''
                }`,
              }))
          : []
      }
      disabled={!watchStudyType}
    />
  );
};

export default StudyProgramSelect;
