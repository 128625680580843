import Typography from '@mui/material/Typography';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { Control } from 'react-hook-form';
import CheckComponent from '/components/form/Check';

interface Props {
  name?: string;
  control: Control<any, any>;
}

const CommunicationCheckbox: React.FC<Props> = ({ control, name }) => {
  const projectId = useProjectId();

  return (
    <CheckComponent
      name={name || 'isMarketingAllowed'}
      control={control}
      noMargin
      label={
        <>
          <Typography variant="body2">
            I want to receive&nbsp;
            <a target="_blank" rel="noopener noreferrer" href={`/${projectId}/about/additional`}>
              additional communication
            </a>
          </Typography>
        </>
      }
    />
  );
};

export default CommunicationCheckbox;
