import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

export interface MutationProps {
  password: string;
  accountInfo: {
    email: string;
    firstName: string;
    lastName: string;
    birthdayDate: string;
    sex: string;
    nationality: string;
    personNumber: string;
    phoneNumber: string;
    arrivalDate: string;
    isMarketingAllowed: boolean;
    isTosAgreed: boolean;
  };
  studyInfo: {
    studyProgramId: any;
    firstSemester: string;
    lastSemester: string;
  };
}

const useRegisterAccount = (onSuccess?: () => void) => {
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  return useMutation({
    mutationFn: async (data: MutationProps) => {
      await Backend.request('post', '/account/signup', {}, data);
      await Backend.request('post', `/account/email/${projectId}`, {}, { email: data.accountInfo.email });
    },
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Failed to create account: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });
};

export default useRegisterAccount;
