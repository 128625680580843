import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { Control, Controller } from 'react-hook-form';
import useDynamicTheme from 'hooks/useDynamicTheme';

interface Props {
  name: string;
  control: Control<any, any>;
  label: React.ReactNode;
  rules?: { [x: string]: any };
  options: { value: string | number; label: string }[];
  disabled?: boolean;
}

/**
 * Renders a full-width Select component with options
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param label label of the input
 * @param rules object of rules for RHF
 * @param options list of {value, label} that are available
 * @param disabled component in disabled mode or not
 */
const SelectComponent: React.FC<Props> = ({ control, label, name, rules, options, disabled = false }) => {
  const theme = useDynamicTheme();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules || {}}
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <>
          <FormControl
            error={!!error}
            fullWidth
            variant="outlined"
            style={{ marginTop: '16px', marginBottom: '16px' }}
            disabled={disabled}
            color="secondary"
          >
            <InputLabel color="secondary">{label}</InputLabel>
            <Select label={label} value={value || ''} color="secondary" {...rest}>
              {options.map((option) => {
                return (
                  <MenuItem
                    key={`select-form-label-option-${option.value}`}
                    value={option.value}
                    color="secondary"
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected} !important`,
                      },
                      '&:hover': {
                        backgroundColor: `${theme.palette.secondary.main}${theme.palette.overlay.opacity.hover} !important`,
                      },
                    }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}
          </FormControl>
        </>
      )}
    />
  );
};

export default SelectComponent;
