import React from 'react';
import StudyTypeSelect from '/containers/forms/StudyTypeSelect';
import StudyProgramSelect from '/containers/forms/StudyProgramSelect';
import { Control } from 'react-hook-form';

interface Props {
  control: Control<any, any>;
  watch: any;
  studyInfo: any;
}

/**
 * Renders the last page of register page
 */
const RegisterStepThree: React.FC<Props> = ({ control, watch, studyInfo }) => {
  const watchStudyType = watch('studyType');

  return (
    <>
      <StudyTypeSelect control={control} studyInfo={studyInfo} />
      <StudyProgramSelect control={control} studyInfo={studyInfo} watchStudyType={watchStudyType} />
    </>
  );
};

export default RegisterStepThree;
