import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
  name?: string;
  label?: string;
}

const FirstNameInput: React.FC<Props> = ({ control, name, label }) => {
  return (
    <TextComponent
      name={name || 'firstName'}
      control={control}
      label={label || 'First Name'}
      rules={{
        required: { value: true, message: 'Enter first name' },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default FirstNameInput;
