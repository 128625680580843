import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';
import FormLogo from '/components/formLogo/FormLogo';

import StepperComponent from '/components/form/Stepper';

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import getUserOptionsQuery from 'queries/user/user-options';
import getUserStudyOptionsQuery from 'queries/user/user-study';
import useRegisterAccount from '../../../queries/user/useRegisterAccount';
import StepCreated from './stepCreated/StepCreated';
import RegisterStepOne from './stepOne/StepOne';
import RegisterStepThree from './stepThree/StepThree';
import RegisterStepTwo from './stepTwo/StepTwo';

/**
 * Default values for first page
 */
const defaultValues = {
  firstName: '',
  lastName: '',
  sex: '',
  nationality: '',
  personNumber: '',
  phoneNumber: '',
  isMarketingAllowed: false,
  termsAgree: false,
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  studyType: null,
  studyProgram: null,
};

interface FormValues {
  firstName: string;
  lastName: string;
  sex: string;
  nationality: string;
  personNumber: string;
  phoneNumber: string;
  isMarketingAllowed: boolean;
  birthdayDate: Date;
  termsAgree: boolean;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  studyType: any | null;
  studyProgram: any | null;
}

/**
 * Title on register page depending on which step it is on!
 */
const pageTitle = (currentStep: number) => {
  switch (currentStep) {
    case 0:
      return 'General Information';
    case 1:
      return 'Account Information';
    case 2:
      return 'Study Information';
    default:
      return 'Create Account';
  }
};

const CreateAccount: React.FC = () => {
  const projectId = useProjectId();
  const { handleSubmit, getValues, control, watch, clearErrors, unregister } = useForm<FormValues>({
    mode: 'all',
    defaultValues,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const registerAccount = useRegisterAccount(() => setCurrentStep(3));
  const { data: generalOptions } = useQuery(getUserOptionsQuery());
  const { data: studyOptions } = useQuery(getUserStudyOptionsQuery());

  const onBack = () => {
    setCurrentStep(currentStep - 1);
    switch (currentStep) {
      case 1:
        clearErrors(['email', 'confirmEmail', 'confirmPassword', 'password']);
        unregister(['email', 'confirmEmail', 'confirmPassword', 'password']);
        break;
      case 2:
        clearErrors(['studyType', 'studyProgram']);
        unregister(['studyType', 'studyProgram']);
        break;
      default:
        break;
    }
  };

  /**
   * When NEXT button is clicked
   */
  const onSubmit = (data: FormValues) => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
    else {
      // data to send to backend
      const creationData = {
        password: data.password,
        accountInfo: {
          email: data.email,
          firstName: data.firstName.trim(),
          lastName: data.lastName.trim(),
          birthdayDate: new Date(
            data.birthdayDate.getTime() + Math.abs(data.birthdayDate.getTimezoneOffset() * 60000)
          ).toISOString(),
          sex: data.sex,
          nationality: data.nationality,
          personNumber: data.personNumber,
          phoneNumber: data.phoneNumber,
          arrivalDate: '2020-08-18T19:11:54.000Z',
          isMarketingAllowed: data.isMarketingAllowed,
          isTosAgreed: true,
        },
        studyInfo: {
          studyProgramId: data.studyProgram,
          firstSemester: '2020-09-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
          lastSemester: '2021-01-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
        },
      };

      registerAccount.mutate(creationData);
    }
  };

  if (!generalOptions || !studyOptions) return <></>;

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          {currentStep !== 3 ? (
            <>
              <Typography variant="h6" style={{ marginTop: '16px', marginBottom: '16px' }}>
                {pageTitle(currentStep)}
              </Typography>
              {currentStep === 0 && (
                <RegisterStepOne
                  control={control}
                  getValues={getValues}
                  profileOptions={generalOptions.general}
                  projectId={projectId}
                />
              )}
              {currentStep === 1 && <RegisterStepTwo control={control} getValues={getValues} />}
              {currentStep === 2 && <RegisterStepThree control={control} watch={watch} studyInfo={studyOptions} />}

              <StepperComponent
                active={currentStep}
                stepAmount={3}
                nextLoading={registerAccount.isPending}
                handleNext={handleSubmit(onSubmit)}
                handleBack={onBack}
              />
            </>
          ) : (
            <StepCreated email={getValues('email')} />
          )}
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default CreateAccount;
