import React from 'react';
import { Control } from 'react-hook-form';
import { StudyOption } from 'types/types';
import { generateTypesFromStudyInfo } from './utils';
import SelectComponent from '/components/form/Select';

interface Props {
  control: Control<any, any>;
  studyInfo: StudyOption[];
}

const StudyTypeSelect: React.FC<Props> = ({ control, studyInfo }) => {
  return (
    <SelectComponent
      control={control}
      name="studyType"
      label="Type of Study"
      rules={{
        required: { value: true, message: 'Enter Study Type' },
      }}
      options={
        studyInfo
          ? generateTypesFromStudyInfo(studyInfo).map((studyType: any, index: number) => ({
              value: index + 1,
              label: studyType,
            }))
          : []
      }
    />
  );
};

export default StudyTypeSelect;
