import React from 'react';
import { Control } from 'react-hook-form';
import DateComponent from '/components/form/Date';

interface Props {
  control: Control<any, any>;
  style?: React.CSSProperties;
  name?: string;
  label?: string;
}

const DateOfBirthInput: React.FC<Props> = ({ control, style, name, label }) => {
  return (
    <DateComponent
      name={name || 'birthdayDate'}
      control={control}
      label={label || 'Date of Birth'}
      style={style || { marginTop: '1rem', marginBottom: '1rem' }}
    />
  );
};

export default DateOfBirthInput;
