import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
  name?: string;
  label?: string;
}

const EmailInput: React.FC<Props> = ({ control, name, label }) => {
  return (
    <TextComponent
      name={name || 'email'}
      control={control}
      label={label || 'E-mail'}
      rules={{
        required: { value: true, message: 'Enter e-mail' },
        pattern: {
          value:
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          message: 'Invalid E-mail address',
        },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default EmailInput;
