/* eslint-disable no-nested-ternary */
import React from 'react';
import { Control } from 'react-hook-form';
import SelectComponent from '/components/form/Select';

interface Props {
  control: Control<any, any>;
  profileOptions: any;
}

const NationalitySelect: React.FC<Props> = ({ control, profileOptions }) => {
  return (
    <SelectComponent
      control={control}
      name="nationality"
      label="Nationality"
      rules={{
        required: { value: true, message: 'Choose a nationality' },
      }}
      options={
        profileOptions?.nationality
          ? profileOptions.nationality
          : profileOptions?.nationalities
            ? profileOptions.nationalities
            : []
      }
    />
  );
};

export default NationalitySelect;
